import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  listAllEvents,
  createEvent,
  deleteEvent,
  updateEvent,
} from "../../api/combobulate-events/events";

export const createEventThunk = createAsyncThunk(
  "events/create",
  async ({ service, event, samplePayload, description }) => {
    const returnEvent = await createEvent(
      service,
      event,
      samplePayload,
      description
    );
    return { returnEvent };
  }
);

export const updateEventThunk = createAsyncThunk(
  "events/update",
  async ({ service, event, updateValues }) => {
    const returnEvent = await updateEvent(service, event, updateValues);
    return { returnEvent };
  }
);

export const listEventThunk = createAsyncThunk("events/list", async () => {
  const events = await listAllEvents();
  return { events };
});

export const deleteEventThunk = createAsyncThunk(
  "events/remove",
  async ({ deletedEvents }) => {
    const events = await Promise.all(
      deletedEvents.map(async (event) => {
        try {
          await deleteEvent(event.service, event.event);
        } catch (e) {
          console.error("Error deleting event");
          return null;
        }
        return event;
      })
    );
    return {
      events: events.filter((event) => event), //filter out null values}
    };
  }
);
