import { useState } from "react";
import { createAccount } from "../../../api/account";
import { SketchPicker } from 'react-color';
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function CreateAccount({addAccount, showAddModal, handleAddClose}) {
  const [accountName, setaccountName] = useState("");
  const [themeColor, setThemeColor] = useState("#f00");


  const handleAccountAdd = (e) => {
    setaccountName(e.target.value);
  };

  const handleCreateAccount = async () => {
    let settings = {
      themeColor
    };
    const resp = await createAccount(accountName, settings);
    addAccount(resp)
    handleAddClose()
  };

  return (
    <div>
      <Modal open={showAddModal} onClose={handleAddClose}>
        <Box sx={style}>
          <TextField
            id="Account Name"
            label="Account Name"
            value={accountName}
            sx={{ width: "100%" }}
            onChange={handleAccountAdd}
          /> <br/>
          <p>Choose a Theme Color:</p>
          <SketchPicker 
            color={themeColor}
            onChangeComplete={(col) => setThemeColor(col.hex)}
          />

          <Button
            onClick={handleCreateAccount}
            sx={{ mt: 2 }}
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        </Box>
      </Modal>
    </div>
  );
}

export default CreateAccount;
