import { createSlice } from "@reduxjs/toolkit";
import state from "./eventState";
import {
  createEventThunk,
  updateEventThunk,
  listEventThunk,
  deleteEventThunk,
} from "./eventThunks";
import xorWith from "lodash/xorWith";
import isEqual from "lodash/isEqual";

const app = createSlice({
  name: "events",
  initialState: state,
  reducers: {
    removeServicesEvents(state, { payload: { serviceNames } }) {
      state.events = state.events.filter(
        (event) => !event.service.includes(serviceNames)
      );
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(createEventThunk.fulfilled, (state, action) => {
        state.events.push(action.payload.returnEvent);
      })

      .addCase(updateEventThunk.fulfilled, (state, action) => {
        const updateIndex = state.events.findIndex(function (event) {
          return (
            event.service === action.payload.returnEvent.service &&
            event.event === action.payload.returnEvent.event
          );
        });
        state.events.splice(updateIndex, 1, action.payload.returnEvent);
      })

      .addCase(deleteEventThunk.fulfilled, (state, action) => {
        state.events = xorWith(state.events, action.payload.events, isEqual);
      })

      .addCase(listEventThunk.fulfilled, (state, action) => {
        state.events = action.payload.events;
      })

      .addCase(listEventThunk.pending, (state) => {
        state.status = "loading";
      });
  },
});

export const { removeServicesEvents } = app.actions;

export default app.reducer;
