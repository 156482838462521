// import api from "./index";
import { API } from "aws-amplify";

export const getAccounts = async () => {
  return await API.get("account-api", "/account");
};

export const createAccount = async (accountName, accountSettings) => {
  try {
    const body = { accountName, accountSettings };
    return await API.post("account-api", "/account", { body });
  } catch (err) {
    return { error: err.message || "Problem fetching account" };
  }
};

export const updateAccount = async (accountId, data) => {
  try {
    const body = { accountId, ...data };
    return await API.put("account-api", "/account", { body });
  } catch (err) {
    return { error: err.message || "Problem fetching account" };
  }
};

export const deleteAccount = async (accountId) => {
  try {
    const body = { accountId };
    return await API.del("account-api", "/account", { body });
  } catch (err) {
    return { error: err.message || "Problem deleting account" };
  }
};
