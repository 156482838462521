import React from "react";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/styles";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import deepEqual from "react-fast-compare";
import { deleteServicesThunk } from "store/service/serviceThunks";

const ServiceTable = () => {
  const dispatch = useDispatch();
  const services = useSelector((state) => state.service.services, deepEqual);
  let theme = createTheme();
  theme = responsiveFontSizes(theme);

  const columns = [
    {
      name: "service",
      label: "Service",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    onRowsDelete: (e) => {
      const deletedServiceNames = e.data.map(
        ({ dataIndex }) => services[dataIndex].service
      );
      dispatch(deleteServicesThunk({ deletedServiceNames }));
    },
  };

  return (
    <ThemeProvider theme={theme}>
      <MUIDataTable
        title={"Service List"}
        data={services}
        columns={columns}
        options={options}
      />
    </ThemeProvider>
  );
};

export default ServiceTable;
