import "./App.css";
import { useState } from "react";
import Account from "./routes/account";
import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import Header from "./components/Header";
import ErrorBoundary from "./ErrorBoundary";
import EventServicePage from "./services/event-service";
import CoreEventService from "./services/core-event-service";
import { Provider } from "react-redux";
import store from "./store/index";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
// TEST IMPORTS
import { useEffect } from "react";
import { API } from "aws-amplify";
// END TEST IMPORTS

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function App({ signOut, user }) {
  const [tab, setTab] = useState(0);

  const userInfo = {
    userId: user?.signInUserSession?.idToken?.payload?.["cognito:username"],
    email: user?.signInUserSession?.idToken?.payload?.email,
    groups: user?.signInUserSession?.idToken?.payload?.["cognito:groups"],
  };

  // TESTING USER ENDPOINT
  const getAllUserAccounts = async () => {
    return await API.get("account-api", "/user");
  };
  const getUserAccount = async () => {
    let body = {
      accountId: "bb7268df-2d20-413b-890b-b15b99451c1d",
    };
    return await API.post(
      "account-api",
      "/user/9b885c3c-da58-4571-b957-1ab0b126b6fd",
      { body }
    );
  };
  const getManyUserAccounts = async () => {
    let body = {
      userIds: [
        "63e9b657-2874-4947-87ee-f606042f36b3",
        "c06c3363-5969-4ea4-94d2-80095f5ef677",
      ],
      accountId: "fe615c49-cdde-4baf-8469-a4f59211a88a",
    };
    return await API.post("account-api", "/user", { body });
  };

  // END OF USER TEST

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Provider store={store}>
      <div className="App">
        <Header logout={signOut} user={userInfo} />
        <Box sx={{ borderBottom: 1, borderColor: "divider", m: "auto" }}>
          <Container maxWidth="xs">
            <Tabs value={tab} onChange={handleChange} aria-label="">
              <Tab label="Accounts" />
              <Tab label="Event Services" />
              <Tab label="Event Core" />
            </Tabs>
          </Container>
        </Box>
        <TabPanel value={tab} index={0}>
          <Account />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <EventServicePage />
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <CoreEventService />
        </TabPanel>
      </div>
    </Provider>
  );
}

export default withAuthenticator(App);
