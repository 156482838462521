import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  listAccounts,
  createNewAccount,
  deleteAccount,
} from "../../api/combobulate-core-events/accounts";

export const createAccountsThunk = createAsyncThunk(
  "accounts/create",
  async ({ awsAccountId, accountId, clientName, awsRegion }) => {
    const returnAccounts = await createNewAccount(
      awsAccountId,
      accountId,
      clientName,
      awsRegion
    );
    return { returnAccounts };
  }
);

export const listAccountsThunk = createAsyncThunk("accounts/list", async () => {
  const accounts = await listAccounts();
  return { accounts };
});

export const deleteAccountsThunk = createAsyncThunk(
  "accounts/delete",
  async ({ accountForDeletion, dataIndex }) => {
    await deleteAccount(accountForDeletion.accountId);
    return dataIndex;
  }
);
