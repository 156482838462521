import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  listAllCoreEventServiceEvents,
  createCoreEventService,
  deleteCoreEventService,
  updateCoreEventService,
  deleteAccountEvents,
} from "../../api/combobulate-core-events/coreEvents";

export const createCoreEventServiceThunk = createAsyncThunk(
  "core-event-services/create",
  async ({ accountId, service, event, description }) => {
    const newCoreEvent = await createCoreEventService(
      accountId,
      service,
      event,
      description
    );
    return { newCoreEvent };
  }
);

export const listCoreEventServiceThunk = createAsyncThunk(
  "core-event-services/list",
  async ({ accountId, service, event, description }) => {
    const coreEvents = await listAllCoreEventServiceEvents(
      accountId,
      service,
      event,
      description
    );
    return { coreEvents };
  }
);

export const updateCoreEventThunk = createAsyncThunk(
  "coreEvents/update",
  async ({ accountId, service, event, description }) => {
    const returnCoreEvent = await updateCoreEventService(
      accountId,
      service,
      event,
      description
    );
    return { returnCoreEvent };
  }
);

export const deleteCoreEventThunk = createAsyncThunk(
  "core-events/delete",
  async ({ eventForDeletion, dataIndex }) => {
    console.log(
      eventForDeletion.accountId,
      eventForDeletion.service,
      eventForDeletion.event
    );
    await deleteCoreEventService(
      eventForDeletion.accountId,
      eventForDeletion.service,
      eventForDeletion.event
    );
    return dataIndex;
  }
);

export const deleteAccountEventsThunk = createAsyncThunk(
  "account-events/delete",
  async ({ accountId }) => {
    await deleteAccountEvents(accountId);
    return accountId;
  }
);
