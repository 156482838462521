import React, { useState, useEffect } from "react";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import BaseCard from "../UI/BaseCard";
import CloseIcon from "@mui/icons-material/Close";
import { createEventThunk, updateEventThunk } from "store/event/eventThunks";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import deepEqual from "react-fast-compare";
import PropTypes from "prop-types";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import Typography from "@mui/material/Typography";

EventForm.propTypes = {
  editData: PropTypes.object,
  clearEditData: PropTypes.func.isRequired,
  closeIconClick: PropTypes.func.isRequired,
  showEditButton: PropTypes.bool.isRequired,
};

function EventForm(props) {
  const { editData, clearEditData, closeIconClick, showEditButton } = props;

  const dispatch = useDispatch();
  const [service, setService] = useState("");
  const [event, setEvent] = useState("");
  const [samplePayload, setSamplePayload] = useState("");
  const [description, setDescription] = useState("");
  const [serviceInvalid, setServiceInvalid] = useState(false);
  const [nameInvalid, setNameInvalid] = useState(false);
  const [invalid, setInvalid] = useState(false);

  useEffect(() => {
    if (event && event.length < 1) {
      setNameInvalid(true);
    }
  }, [event]);

  useEffect(() => {
    if (service && service.length < 1) {
      setServiceInvalid(true);
    }
  }, [service]);

  //Mapping
  const services = useSelector((state) => state.service.services, deepEqual);
  const selectServiceTitle = services.map((service) => service.service);

  //Kebab Case update function

  const kebabCase = (string) =>
    string
      .replace(/([a-z])([A-Z])/g, "$1-$2")
      .replace(/[\s_]+/g, "-")
      .toLowerCase();

  //Input value set from edit data
  useEffect(() => {
    if (editData) {
      setService(editData.service);
      setEvent(editData.event);
      setSamplePayload(editData.samplePayload);
      setDescription(editData.description);
    }
  }, [editData]);

  //Edit the input row
  const editInputs = () => {
    setSamplePayload(samplePayload);
    setDescription(description);
    const updateValues = { samplePayload, description };
    dispatch(updateEventThunk({ service, event, updateValues }));
    clearData();
    handleModalClose();
  };

  const handleServiceSelect = (e) => {
    let selectedService = e.target.value;
    setService(selectedService);
    if (e.target.value.length > 0) {
      setServiceInvalid(false);
    }
  };

  const handleNameInput = (e) => {
    let inputName = e.target.value;
    let kebabInputName = kebabCase(inputName);
    setEvent(kebabInputName);
    if (e.target.value.length > 0) {
      setNameInvalid(false);
    }
  };

  const handlesamplePayloadInput = (value) => {
    if (value.error) {
      setInvalid(true);
    } else {
      setInvalid(false);
      setSamplePayload(value.json);
    }
  };

  const handleDescriptionInput = (e) => {
    let inputDescription = e.target.value;
    setDescription(inputDescription);
  };

  const clearData = () => {
    clearEditData();
    setService("");
    setEvent("");
    setSamplePayload("");
    setDescription("");
  };

  //Modal Close
  const handleModalClose = () => {
    clearData();
    closeIconClick();
  };

  //Create New Event
  const createCombobEvent = (e) => {
    e.preventDefault();
    if (serviceInvalid || nameInvalid) {
      return;
    } else {
      const newEvent = { service, event, samplePayload, description };
      dispatch(createEventThunk(newEvent));
      clearData();
      handleModalClose();
    }
  };

  return (
    <form onSubmit={createCombobEvent}>
      <BaseCard cardTitle="Create Event">
        <CloseIcon
          onClick={handleModalClose}
          sx={{ mt: -5, float: "right", cursor: "pointer" }}
        />
        <FormControl sx={{ display: "flex" }}>
          <InputLabel sx={{ mt: 4 }} id="service-select-drop">
            Service
          </InputLabel>
          <Select
            error={serviceInvalid}
            value={service || ""}
            disabled={showEditButton}
            sx={{ mb: 2, mt: 4 }}
            labelId="service-select-drop"
            id="demo-simple-select"
            label="Service"
            onChange={handleServiceSelect}
          >
            {selectServiceTitle.map((service, index) => (
              <MenuItem key={index} value={service || ""}>
                {service}
              </MenuItem>
            ))}
          </Select>

          <TextField
            disabled={showEditButton}
            error={nameInvalid}
            value={event || ""}
            sx={{ mb: 2 }}
            id="name-input"
            label="Name"
            variant="outlined"
            onChange={handleNameInput}
          />

          <Typography variant="overline">Payload</Typography>
          <JSONInput
            id="samplePayload-input"
            // placeholder={{ samplePayload }}
            locale={locale}
            width="100%"
            height="200px"
            onChange={handlesamplePayloadInput}
            theme="dark_vscode_tribute"
          />

          <TextField
            value={description}
            sx={{ mb: 2, mt: 2 }}
            id="description"
            label="Description"
            variant="outlined"
            multiline
            rows={3}
            onChange={handleDescriptionInput}
          />

          <Stack spacing={2} direction="row">
            {!showEditButton ? (
              <Button
                variant="contained"
                sx={{ maxWidth: 100, display: "inline" }}
                type="submit"
                disabled={invalid}
              >
                Create
              </Button>
            ) : (
              <></>
            )}

            {showEditButton ? (
              <Button
                variant="contained"
                sx={{ maxWidth: 100, display: "inline" }}
                onClick={editInputs}
              >
                Edit
              </Button>
            ) : (
              <></>
            )}
          </Stack>
        </FormControl>
      </BaseCard>
    </form>
  );
}

export default EventForm;
