import { createSlice } from "@reduxjs/toolkit";
import state from "./coreEventServiceState";
import {
  createCoreEventServiceThunk,
  listCoreEventServiceThunk,
  updateCoreEventThunk,
  deleteCoreEventThunk,
  deleteAccountEventsThunk,
} from "./coreEventServiceThunks";

const app = createSlice({
  name: "core-event-services",
  initialState: state,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createCoreEventServiceThunk.fulfilled, (state, action) => {
        state.coreEvents.push(action.payload.newCoreEvent);
      })

      .addCase(updateCoreEventThunk.fulfilled, (state, action) => {
        const updateIndex = state.coreEvents.findIndex(function (event) {
          return (
            event.accountId === action.payload.returnCoreEvent.accountId &&
            event.service === action.payload.returnCoreEvent.service &&
            event.event === action.payload.returnCoreEvent.event
          );
        });
        state.coreEvents.splice(updateIndex, 1, action.payload.returnCoreEvent);
      })

      .addCase(deleteCoreEventThunk.fulfilled, (state, action) => {
        state.coreEvents.splice(action.payload, 1);
      })

      .addCase(listCoreEventServiceThunk.fulfilled, (state, action) => {
        state.coreEvents = action.payload.coreEvents;
      })

      .addCase(deleteAccountEventsThunk.fulfilled, (state, action) => {
        state.coreEvents = state.coreEvents.filter((event) => {
          return event.accountId !== action.payload;
        });
      });
  },
});

export default app.reducer;
