import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/styles";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import deepEqual from "react-fast-compare";
import { deleteEventThunk } from "store/event/eventThunks";
import PropTypes from "prop-types";

EventTable.propTypes = {
  openEditModal: PropTypes.func.isRequired,
};

function EventTable(props) {
  const { openEditModal } = props;

  const dispatch = useDispatch();
  const events = useSelector((state) => state.event.events, deepEqual);
  let theme = createTheme();
  theme = responsiveFontSizes(theme);

  //Edit button click in table - passed to EventService Page
  const handleModalEdit = (dataIndex) => {
    openEditModal(dataIndex);
  };

  const columns = [
    {
      name: "Edit",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          return (
            <button
              onClick={() => {
                handleModalEdit(dataIndex);
              }}
            >
              Edit
            </button>
          );
        },
      },
    },
    {
      name: "service",
      label: "Service",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "event",
      label: "Event Name",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "samplePayload",
      label: "SamplePayload",
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  const options = {
    filterType: "checkbox",

    onRowsDelete: (e) => {
      const deletedEvents = e.data.map(({ dataIndex }) => events[dataIndex]);
      dispatch(deleteEventThunk({ deletedEvents }));
    },
  };

  return (
    <ThemeProvider theme={theme}>
      <MUIDataTable
        title={"Events List"}
        data={events}
        columns={columns}
        options={options}
      />
    </ThemeProvider>
  );
}

export default EventTable;
