import React, { useState, useEffect } from "react";

import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import BaseCard from "../UI/BaseCard";
import CloseIcon from "@mui/icons-material/Close";
import { listEventThunk } from "store/event/eventThunks";
import { listServiceThunk } from "store/service/serviceThunks";
import {
  createCoreEventServiceThunk,
  updateCoreEventThunk,
} from "store/core-events/coreEventServiceThunks";
import { useDispatch, useSelector } from "react-redux";
import deepEqual from "react-fast-compare";
import PropTypes from "prop-types";

EventForm.propTypes = {
  editEventData: PropTypes.object,
  showEditButton: PropTypes.bool.isRequired,
  mainModalClose: PropTypes.func.isRequired,
};

function EventForm(props) {
  const dispatch = useDispatch();

  const { mainModalClose, editEventData, showEditButton } = props;

  useEffect(() => {
    dispatch(listEventThunk({}));
    dispatch(listServiceThunk({}));
  }, [dispatch]);

  const [accountId, setAccountId] = useState("");
  const [service, setService] = useState("");
  const [event, setEvent] = useState("");
  const [description, setDescription] = useState("");

  const [filterEvents, setFilterEvents] = useState([]);

  const [accountIdInvalid, setAccountIdInvalid] = useState(false);
  const [serviceInvalid, setServiceInvalid] = useState(false);
  const [eventInvalid, setEventInvalid] = useState(false);
  const cardWidth = 800;

  // //Mapping
  const accounts = useSelector((state) => state.accounts.accounts, deepEqual);
  const accountIds = accounts.map((account) => account.accountId);

  const services = useSelector((state) => state.service.services, deepEqual);
  const serviceNames = services.map((service) => service.service);

  const events = useSelector((state) => state.event.events, deepEqual);

  //Kebab Case update function
  useEffect(() => {
    const filterEventArray = events.filter(
      (event) => event.service.indexOf(service) !== -1
    );
    setFilterEvents(filterEventArray.map((event) => event.event));
  }, [service, events]);

  // //Input value set from edit data
  useEffect(() => {
    if (editEventData) {
      setAccountId(editEventData.accountId);
      setService(editEventData.service);
      setEvent(editEventData.event);
      setDescription(editEventData.description);
    }
  }, [editEventData]);

  // //Edit the input row
  const editEventInputs = () => {
    setDescription(description);
    dispatch(updateCoreEventThunk({ accountId, service, event, description }));
    mainModalClose();
  };

  const handleAccountIdSelect = (e) => {
    const accountIdSelect = e.target.value;
    setAccountId(accountIdSelect);
    if (accountIdSelect.length > 0) {
      setAccountIdInvalid(false);
    }
  };

  const handleServiceSelect = (e) => {
    setService(e.target.value);
    if (e.target.value.length > 0) {
      setServiceInvalid(false);
    }
  };

  const handleEventSelect = (e) => {
    setEvent(e.target.value);
    if (e.target.value.length > 0) {
      setEventInvalid(false);
    }
  };

  const handledescriptionInput = (e) => {
    setDescription(e.target.value);
  };

  const clearData = () => {
    setAccountId("");
    setService("");
    setEvent("");
    setDescription("");
  };

  // //Modal Close
  const closeModal = () => {
    clearData();
    mainModalClose();
  };

  //Create New Event
  const createCoreEvent = (e) => {
    e.preventDefault();
    if (accountId == null) {
      setAccountIdInvalid(true);
    }
    if (service == null) {
      setServiceInvalid(true);
    }
    if (event == null) {
      setEventInvalid(true);
    } else {
      const newCoreEvent = { accountId, service, event, description };
      dispatch(createCoreEventServiceThunk(newCoreEvent));
      closeModal();
    }
  };

  return (
    <form onSubmit={createCoreEvent}>
      <BaseCard cardTitle="Create Event" maxWidth={cardWidth}>
        <CloseIcon
          onClick={closeModal}
          sx={{ mt: -5, float: "right", cursor: "pointer" }}
        />

        <FormControl sx={{ display: "flex" }}>
          <TextField
            error={accountIdInvalid}
            value={accountId}
            select
            disabled={showEditButton}
            sx={{ mb: 1, mt: 4 }}
            label="Account Id"
            id="accountId-drop"
            onChange={handleAccountIdSelect}
            helperText={accountIdInvalid ? "Value Required" : " "}
          >
            {accountIds.map((accountId, index) => (
              <MenuItem key={accountId} value={accountId || ""}>
                {accountId}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            error={serviceInvalid}
            value={service}
            select
            disabled={showEditButton}
            sx={{ mb: 1 }}
            label="Service"
            id="service-drop"
            onChange={handleServiceSelect}
            helperText={serviceInvalid ? "Value Required" : " "}
          >
            {serviceNames.map((service, index) => (
              <MenuItem key={service} value={service || ""}>
                {service}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            error={eventInvalid}
            value={event}
            select
            disabled={showEditButton || serviceInvalid}
            sx={{ mb: 1 }}
            label="Event"
            id="service-drop"
            onChange={handleEventSelect}
            helperText={eventInvalid ? "Value Required" : " "}
          >
            {filterEvents.map((event, index) => (
              <MenuItem key={event} value={event || ""}>
                {event}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            value={description}
            sx={{ mb: 1, mt: 1 }}
            id="description"
            label="Event Description"
            variant="outlined"
            multiline
            rows={3}
            onChange={handledescriptionInput}
          />
          <Stack spacing={2} direction="row">
            {!showEditButton ? (
              <Button
                variant="contained"
                sx={{ maxWidth: 100, display: "inline" }}
                type="submit"
              >
                Create
              </Button>
            ) : (
              <></>
            )}

            {showEditButton ? (
              <Button
                variant="contained"
                sx={{ maxWidth: 100, display: "inline" }}
                onClick={editEventInputs}
              >
                Edit
              </Button>
            ) : (
              <></>
            )}
          </Stack>
        </FormControl>
      </BaseCard>
    </form>
  );
}

export default EventForm;
