import React, { useState, useEffect } from "react";
import BaseCard from "./UI/BaseCard";
import AccountTable from "./components/AccountTable";
import EventTable from "./components/EventTable";
import CoreEventServiceModal from "./components/CoreEventServiceModal";
import Button from "@mui/material/Button";
import { ConfirmProvider } from "material-ui-confirm";

import { listAccountsThunk } from "store/accounts/accountsThunks";
import { listCoreEventServiceThunk } from "store/core-events/coreEventServiceThunks";
import { useSelector, useDispatch } from "react-redux";
import deepEqual from "react-fast-compare";
import { getKey } from "../../api/key";

const CoreEventService = () => {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [editEventData, setEditEventData] = useState({});
  const [showEditButton, setShowEditButton] = useState(false);

  const coreEvents = useSelector(
    (state) => state.coreEvents.coreEvents,
    deepEqual
  );

  useEffect(() => {
    get();
  }, []);

  const get = async () => {
    try {
      const key = await getKey();
      localStorage.setItem("events-api-key", key.key);
      dispatch(listAccountsThunk({}));
      dispatch(listCoreEventServiceThunk({}));
    } catch (error) {
      console.log("api key not set");
    }
  };

  //Open and Close Modal
  const handleModalOpen = (modalType) => {
    setEditEventData({});
    setShowEditButton(false);
    setModalOpen(true);
    setModalType(modalType);
  };

  const mainModalClose = () => {
    setModalOpen(false);
  };

  // Edit button click passed from table
  const openEditModal = (index, modalType) => {
    const editEventGrab = coreEvents[index];
    setEditEventData(editEventGrab);
    setModalType(modalType);
    setModalOpen(true);
    setShowEditButton(true);
  };

  //Clear Edit data
  const clearEditData = () => {
    setEditEventData({});
  };

  return (
    <>
      <Button
        variant="contained"
        sx={{ mt: 3, mr: 3 }}
        onClick={() => handleModalOpen("account")}
      >
        Create Account
      </Button>

      <Button
        variant="contained"
        sx={{ mt: 3 }}
        onClick={() => handleModalOpen("event")}
      >
        Create Core Event
      </Button>

      <CoreEventServiceModal
        mainModalClose={mainModalClose}
        modalOpen={modalOpen}
        modalType={modalType}
        editEventData={editEventData}
        clearEditData={clearEditData}
        showEditButton={showEditButton}
      />

      <BaseCard cardTitle="">
        <ConfirmProvider>
          <AccountTable />
        </ConfirmProvider>
      </BaseCard>

      <BaseCard cardTitle="">
        <ConfirmProvider>
          <EventTable openEditModal={openEditModal} />
        </ConfirmProvider>
      </BaseCard>
    </>
  );
};

export default CoreEventService;
