import axios from "axios";
const base = `${process.env.REACT_APP_EVENTS_API}/api/v1/services`; //change this approach
axios.defaults.headers.common["events-api-key"] =
  localStorage.getItem("events-api-key");

// CORRECT API TO USE WHEN SET UP ** DO NO REMOVE
export const listServices = async () => {
  try {
    const response = await axios.get(base);
    const services = response.data.data;
    return services;
  } catch (e) {
    console.log("API Error", e);
    throw e;
  }
};

export const createService = async (service, description) => {
  try {
    const response = await axios.post(`${base}`, { service, description });
    return response.data;
  } catch (e) {
    console.log("API Error", e);
    throw e;
  }
};

export const deleteService = async (service) => {
  try {
    const response = await axios.delete(`${base}/service/${service}`);
    return response.data;
  } catch (e) {
    console.log("API Error", e);
    throw e;
  }
};
