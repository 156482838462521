import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/styles";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import deepEqual from "react-fast-compare";
import { deleteCoreEventThunk } from "store/core-events/coreEventServiceThunks";
import { useConfirm } from "material-ui-confirm";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import PropTypes from "prop-types";

EventTable.propTypes = {
  openEditModal: PropTypes.func.isRequired,
};

function EventTable(props) {
  const { openEditModal } = props;

  const confirm = useConfirm();
  const dispatch = useDispatch();
  const coreEvents = useSelector(
    (state) => state.coreEvents.coreEvents,
    deepEqual
  );

  let theme = createTheme({
    components: {
      MUIDataTableToolbar: {
        styleOverrides: {
          root: {
            textAlign: "left",
          },
        },
      },
    },
  });
  theme = responsiveFontSizes(theme);

  const handleModalEdit = (dataIndex, modalType) => {
    openEditModal(dataIndex, modalType);
  };

  const columns = [
    {
      name: "Edit",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          return (
            <button
              onClick={() => {
                handleModalEdit(dataIndex, "event");
              }}
            >
              Edit
            </button>
          );
        },
      },
    },
    {
      name: "accountId",
      label: "Account Id",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "service",
      label: "Service",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "event",
      label: "Event",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "eventRuleArn",
      label: "Event Rule Arn",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "eventTargetId",
      label: "Event Target Id",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "Delete",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          return (
            <IconButton
              aria-label="delete"
              color="warning"
              onClick={() => {
                deleteRow(dataIndex);
              }}
            >
              <DeleteIcon />
            </IconButton>
          );
        },
      },
    },
  ];

  const options = {
    selectableRows: "none",
  };

  const deleteRow = async (dataIndex) => {
    const eventForDeletion = coreEvents[dataIndex];
    const coreEvent = coreEvents[dataIndex].service;
    await confirm({
      description: `This will permanently delete Event: ${coreEvent}.`,
    });
    dispatch(deleteCoreEventThunk({ eventForDeletion, dataIndex }));
  };

  return (
    <ThemeProvider theme={theme}>
      <MUIDataTable
        title={"Event Subscriptions"}
        data={coreEvents}
        columns={columns}
        options={options}
      />
    </ThemeProvider>
  );
}

export default EventTable;
