import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  listServices,
  createService,
  deleteService,
} from "../../api/combobulate-events/services";
import { removeServicesEvents } from "store/event/eventReducer";

export const createServiceThunk = createAsyncThunk(
  "services/create",
  async ({ serviceName, serviceDescription }) => {
    const returnService = await createService(serviceName, serviceDescription);
    return { returnService };
  }
);

export const listServiceThunk = createAsyncThunk("services/list", async () => {
  const services = await listServices();
  return { services };
});

export const deleteServicesThunk = createAsyncThunk(
  "services/remove",
  async ({ deletedServiceNames }, ThunkAPI) => {
    const responses = await Promise.all(
      deletedServiceNames.map(async (service) => {
        try {
          const response = await deleteService(service);
          if (!response.success) return null;
          return service;
        } catch (e) {
          console.error(`Failed to delete ${service}`);
          return null;
        }
      })
    );
    const serviceNamesSuccessful = responses.filter((success) => success);
    ThunkAPI.dispatch(
      removeServicesEvents({ serviceNames: serviceNamesSuccessful })
    );

    return { deletedServiceNames: serviceNamesSuccessful };
  }
);
