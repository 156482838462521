import { useEffect, useState } from "react";
import { getAccounts, deleteAccount } from "../../api/account";
import CreateAccount from "./components/CreateAccount";
import UpdateAccount from "./components/UpdateAccount";
import "./index.css"
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/styles";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";

function SecretField({secret}) {
  const [showSecret, setShowSecret] = useState(false)
  return (
    <div>
      <div>
        {showSecret ? secret : secret.split('').map(x => '*') } 
      </div>
      <Button variant="contained" color="secondary" onClick={() => setShowSecret(!showSecret)}>
        Show Secret
      </Button>
    </div>
  )
}

function Account() {
  const [accounts, accountSet] = useState([]);
  const [errorText, setErrorText] = useState("");
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdModal, setShowUpdModal] = useState(false);
  const [updId, setUpdId] = useState();

  let theme = createTheme({
    components: {
      MUIDataTableToolbar: {
        styleOverrides: {
          root: {
            textAlign: "left",
          },
        },
      },
    },
  });
  theme = responsiveFontSizes(theme);

  const columns = [
    {
      name: "accountName",
      label: "Account Name",
    },
    {
      name: "accountId",
      label: "Id",
    },
    {
      name: "tokenSecret",
      label: "Secret",
      options: {
        customBodyRenderLite: (idx) => {
          let value = accounts[idx].tokenSecret;
          return (
            <SecretField secret={value} key={value}/>
          );
        },
      }
    },
    {
      name: "themeColor",
      label: "Color",
      options: {
        customBodyRenderLite: (id) => {
          if(accounts[id]?.accountSettings?.themeColor){
            let col = accounts[id].accountSettings.themeColor;
            return <div style={{backgroundColor: `${col}`}}>
              {accounts[id].accountSettings.themeColor}</div>;
          }
          return 'N/A';
        }
      }
    },
    {
      name: "Edit",
      label: "Edit",
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRenderLite: (id) => {
          return <Button onClick={() => handleEdit(id)}>Edit</Button>
        }
      }
    }
  ];

  const options = {
    filterType: "checkbox",
    onRowsDelete: (e) => {
      e.data.forEach(async (i) => {
        let acc = accounts[i.dataIndex]
        const del = await deleteAccount(acc.accountId);
      });
    }
  };

  useEffect(() => {
    get();
  }, []);

  const get = async () => {
    try {
      const accounts = await getAccounts();
      accountSet(accounts);
    } catch (error) {
      setErrorText(error.message);
    }
  };

  const handleEdit = (id) => {
    setUpdId(id);
    handleUpdOpen();
  }

  const addAccount = (account) => {
    accountSet(acc => ([...acc, account]));
  }

  const updAccount = (account) => {
    let ac = accounts;
    ac.splice(updId, 1, account);
    accountSet(ac);
  }

  const handleAddOpen = () => setShowAddModal(true);
  const handleAddClose = () => setShowAddModal(false);

  const handleUpdOpen = () => setShowUpdModal(true);
  const handleUpdClose = () => setShowUpdModal(false);


  return (
    <div className="accounts">
      {errorText}
      <Box sx={{ mb: 2, display: "flex", justifyContent: "end" }}>
        <Button onClick={handleAddOpen} variant="contained" color="primary">
          Add Account
        </Button>
      </Box>
        <CreateAccount handleAddClose={handleAddClose} addAccount={addAccount} setShowAddModal={setShowAddModal} showAddModal={showAddModal}/>
        <UpdateAccount updRow={accounts[updId]} handleUpdClose={handleUpdClose} updAccount={updAccount} setShowUpdModal={setShowUpdModal} showUpdModal={showUpdModal} />
      <ThemeProvider theme={theme}>
      <MUIDataTable
        title={"Accounts"}
        data={accounts}
        columns={columns}
        options={options}
      />
      </ThemeProvider>
    </div>
  );
}

export default Account;
