import "./Header.css";

function Header({ user, logout }) {
  return (
    <div className="header">
      <h2>Combobulate Management</h2>
      <div className="header-info">
        <span>Signed in as: {user.email}</span>
        <button onClick={logout}>Sign Out</button>
      </div>
    </div>
  );
}

export default Header;
