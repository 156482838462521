import * as React from "react";
import Modal from "@mui/material/Modal";
import EventForm from "./EventForm";
import ServiceForm from "./ServiceForm";
import PropTypes from "prop-types";

EventServiceModal.propTypes = {
  editIndex: PropTypes.number,
  modalType: PropTypes.string.isRequired,
  serviceNames: PropTypes.array.isRequired,
  clearEdit: PropTypes.func.isRequired,
  mainModalClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  updateData: PropTypes.func.isRequired,
  editData: PropTypes.object.isRequired,
  showEditButton: PropTypes.bool.isRequired,
};

function EventServiceModal(props) {
  const {
    editIndex,
    modalType,
    serviceNames,
    clearEdit,
    mainModalClose,
    open,
    updateData,
    editData,
    showEditButton,
  } = props;

  const closeIconClick = () => {
    mainModalClose();
  };

  const clearEditData = () => {
    clearEdit();
  };

  return (
    <div>
      <Modal open={open}>
        <>
          {modalType === "service" ? (
            <ServiceForm
              closeIconClick={closeIconClick}
              serviceData={serviceNames}
            />
          ) : (
            <EventForm
              closeIconClick={closeIconClick}
              editData={editData}
              serviceData={serviceNames}
              clearEditData={clearEditData}
              showEditButton={showEditButton}
              editIndex={editIndex}
              updateData={updateData}
            />
          )}
        </>
      </Modal>
    </div>
  );
}

export default EventServiceModal;
