import axios from "axios";
const base = `${process.env.REACT_APP_CORE_API}/api/v1/accounts`;
axios.defaults.headers.common["events-api-key"] =
  localStorage.getItem("events-api-key");

export const listAccounts = async () => {
  try {
    const response = await axios.get(`${base}`);
    const accounts = response.data.data;
    if (!response.data.success) {
      throw Error("list accounts failed");
    }
    return accounts;
  } catch (e) {
    console.log("API Error", e);
    throw e;
  }
};

export const createNewAccount = async (
  awsAccountId,
  accountId,
  clientName,
  awsRegion
) => {
  try {
    const response = await axios.post(base, {
      awsAccountId,
      accountId,
      clientName,
      awsRegion,
    });
    return response.data.data;
  } catch (e) {
    console.log("API Error", e);
    throw e;
  }
};

export const deleteAccount = async (accountId) => {
  try {
    const response = await axios.delete(`${base}/account/${accountId}`);
    if (!response.data.success) {
      throw Error("delete accounts failed");
    }
    return response.data.data;
  } catch (e) {
    console.log("API Error", e);
    throw e;
  }
};
