import { createSlice } from "@reduxjs/toolkit";
import state from "./accountsState";
import {
  createAccountsThunk,
  listAccountsThunk,
  deleteAccountsThunk,
} from "./accountsThunks";

const app = createSlice({
  name: "accounts",
  initialState: state,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(listAccountsThunk.fulfilled, (state, action) => {
        state.accounts = action.payload.accounts;
      })

      .addCase(createAccountsThunk.fulfilled, (state, action) => {
        state.accounts.push(action.payload.returnAccounts);
      })

      .addCase(deleteAccountsThunk.fulfilled, (state, action) => {
        state.accounts.splice(action.payload, 1);
      });
  },
});

export default app.reducer;
