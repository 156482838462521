import React, { useState } from "react";

import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import BaseCard from "../UI/BaseCard";
import CloseIcon from "@mui/icons-material/Close";
import { createAccountsThunk } from "store/accounts/accountsThunks";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { regions } from "./Regions";

EventForm.propTypes = {
  mainModalClose: PropTypes.func.isRequired,
};

function EventForm(props) {
  const { mainModalClose } = props;

  const dispatch = useDispatch();

  const [awsAccountId, setawsAccountId] = useState("");
  const [accountId, setAccountId] = useState("");
  const [clientName, setClientName] = useState("");
  const [awsRegion, setAwsRegion] = useState("");

  const [awsAccountIdInvalid, setawsAccountIdInvalid] = useState(false);
  const [accountIdInvalid, setAccountIdInvalid] = useState(false);
  const [clientNameInvalid, setClientNameInvalid] = useState(false);
  const [awsRegionInvalid, setAwsRegionInvalid] = useState(false);

  const cardWidth = 800;

  //Kebab Case update function
  const kebabCase = (string) =>
    string
      .replace(/([a-z])([A-Z])/g, "$1-$2")
      .replace(/[\s_]+/g, "-")
      .toLowerCase();

  const handleawsAccountIdInput = (e) => {
    let kebabAwsAccountId = kebabCase(e.target.value);
    setawsAccountId(kebabAwsAccountId);
    if (e.target.value.length > 0) {
      setawsAccountIdInvalid(false);
    }
  };

  const handleAccountIdInput = (e) => {
    let kebabAccountId = kebabCase(e.target.value);
    setAccountId(kebabAccountId);
    if (e.target.value.length > 0) {
      setAccountIdInvalid(false);
    }
  };

  const handleClientNameInput = (e) => {
    let kebabClientName = kebabCase(e.target.value);
    setClientName(kebabClientName);
    if (e.target.value.length > 0) {
      setClientNameInvalid(false);
    }
  };

  const handleAwsRegionInput = (e) => {
    let kebabAwsRegion = kebabCase(e.target.value);
    setAwsRegion(kebabAwsRegion);
    if (e.target.value.length > 0) {
      setAwsRegionInvalid(false);
    }
  };

  const clearData = () => {
    setawsAccountId("");
    setAccountId("");
    setClientName("");
    setAwsRegion("");
  };

  //Modal Close
  const closeModal = () => {
    mainModalClose();
  };

  //Create New Event
  const createAccount = (e) => {
    e.preventDefault();
    if (awsAccountId.length <= 0) {
      setawsAccountIdInvalid(true);
    }
    if (accountId <= 0) {
      setAccountIdInvalid(true);
    }
    if (clientName.length <= 0) {
      setClientNameInvalid(true);
    }
    if (awsRegion.length <= 0) {
      setAwsRegionInvalid(true);
    } else {
      const newAccount = { awsAccountId, accountId, clientName, awsRegion };
      dispatch(createAccountsThunk(newAccount));
      clearData();
      closeModal();
    }
  };

  return (
    <form onSubmit={createAccount}>
      <BaseCard cardTitle="Create Account" maxWidth={cardWidth}>
        <CloseIcon
          onClick={closeModal}
          sx={{ mt: -5, float: "right", cursor: "pointer" }}
        />
        <FormControl sx={{ display: "flex" }}>
          <TextField
            error={awsAccountIdInvalid}
            value={awsAccountId}
            sx={{ mb: 1 }}
            id="awsAccountId"
            label="AWS Input Id"
            variant="outlined"
            onChange={handleawsAccountIdInput}
            helperText={awsAccountIdInvalid ? "Value Required" : " "}
          />

          <TextField
            value={accountId}
            error={accountIdInvalid}
            sx={{ mb: 1, mt: 1 }}
            id="accountId"
            label="Account Id"
            variant="outlined"
            multiline
            onChange={handleAccountIdInput}
            helperText={accountIdInvalid ? "Value Required" : " "}
          />

          <TextField
            value={clientName}
            error={clientNameInvalid}
            sx={{ mb: 1, mt: 1 }}
            id="clientName"
            label="Client Name"
            variant="outlined"
            multiline
            onChange={handleClientNameInput}
            helperText={clientNameInvalid ? "Value Required" : " "}
          />

          <TextField
            value={awsRegion}
            error={awsRegionInvalid}
            select
            sx={{ mb: 1, mt: 1 }}
            id="awsRegion"
            label="AWS Region"
            variant="outlined"
            multiline
            onChange={handleAwsRegionInput}
            helperText={awsRegionInvalid ? "Value Required" : " "}
          >
            {regions.map((awsRegion, index) => (
              <MenuItem key={awsRegion} value={awsRegion || ""}>
                {awsRegion}
              </MenuItem>
            ))}
          </TextField>

          <Button
            variant="contained"
            sx={{ maxWidth: 100, display: "inline" }}
            type="submit"
          >
            Create
          </Button>
        </FormControl>
      </BaseCard>
    </form>
  );
}

export default EventForm;
