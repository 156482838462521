import * as React from "react";
import Modal from "@mui/material/Modal";
import EventForm from "./EventForm";
import AccountForm from "./AccountForm";
import PropTypes from "prop-types";

CoreEventServiceModal.propTypes = {
  modalType: PropTypes.string.isRequired,
  clearEditData: PropTypes.func.isRequired,
  mainModalClose: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  showEditButton: PropTypes.bool.isRequired,
};

function CoreEventServiceModal(props) {
  const {
    modalType,
    mainModalClose,
    modalOpen,
    editEventData,
    showEditButton,
    clearEditData,
  } = props;

  return (
    <div>
      <Modal open={modalOpen}>
        <>
          {modalType === "event" ? (
            <EventForm
              mainModalClose={mainModalClose}
              editEventData={editEventData}
              clearEditData={clearEditData}
              showEditButton={showEditButton}
            />
          ) : (
            <AccountForm mainModalClose={mainModalClose} />
          )}
        </>
      </Modal>
    </div>
  );
}

export default CoreEventServiceModal;
