import axios from "axios";
const base = `${process.env.REACT_APP_CORE_API}/api/v1/events`; //change this approach
axios.defaults.headers.common["events-api-key"] =
  localStorage.getItem("events-api-key");

export const listAllCoreEventServiceEvents = async () => {
  try {
    const response = await axios.get(base);
    const coreEvents = response.data.data;
    if (!response.data.success) {
      throw Error("list events failed");
    }
    return coreEvents;
  } catch (e) {
    console.log("API Error", e);
    throw e;
  }
};

export const createCoreEventService = async (
  accountId,
  service,
  event,
  description
) => {
  try {
    const response = await axios.post(`${base}`, {
      accountId,
      service,
      event,
      description,
    });
    if (!response.data.success) {
      throw Error("create event failed");
    }
    return response.data.data;
  } catch (e) {
    console.log("API Error", e);
    throw e;
  }
};

export const updateCoreEventService = async (
  accountId,
  service,
  event,
  description
) => {
  try {
    const response = await axios.put(
      `${base}/account/${accountId}/service/${service}/event/${event}`,
      { description }
    );
    if (!response.data.success) {
      throw Error("update event failed");
    }
    return { accountId, service, event, description, ...response.data.data };
  } catch (e) {
    console.log("API Error", e);
    throw e;
  }
};

export const deleteCoreEventService = async (accountId, service, event) => {
  try {
    const response = await axios.delete(
      `${base}/account/${accountId}/service/${service}/event/${event}`
    );
    return response.data.data;
  } catch (e) {
    console.log("API Error", e);
    throw e;
  }
};

export const deleteAccountEvents = async (accountId) => {
  try {
    const response = await axios.delete(`${base}/account/${accountId}`);
    return response.data.data;
  } catch (e) {
    console.log("API Error", e);
    throw e;
  }
};
