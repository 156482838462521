import { useState, useEffect } from "react";
import { updateAccount } from "../../../api/account";
import { SketchPicker } from 'react-color';
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function UpdateAccount({updAccount, showUpdModal, handleUpdClose, updRow}) {
  const [accountName, setAccountName] = useState("");
  const [themeColor, setThemeColor] = useState("");


  const handleAccountUpdate = (e) => {
    setAccountName(e.target.value);
  };

  const handleUpdateAccount = async () => {
    let data = {
      accountName,
      accountSettings: {themeColor},
    };
    const resp = await updateAccount(updRow.accountId, data);
    updAccount(resp)
    handleUpdClose();
  };

  useEffect(() => {
    if(updRow){
      if(updRow.accountName) setAccountName(updRow.accountName);
      if(updRow.accountSettings && updRow.accountSettings.themeColor) {
        setThemeColor(updRow.accountSettings.themeColor);
      }
    }
  }, [showUpdModal])

  return (
    <div>
      <Modal open={showUpdModal} onClose={handleUpdClose}>
        <Box sx={style}>
          <TextField
            id="Account Name"
            label="Account Name"
            value={accountName}
            sx={{ width: "100%" }}
            onChange={handleAccountUpdate}
          /> <br/>
          <p>Choose a Theme Color:</p>
          <SketchPicker 
            color={themeColor}
            onChangeComplete={(col) => setThemeColor(col.hex)}
          />

          <Button
            onClick={handleUpdateAccount}
            sx={{ mt: 2 }}
            variant="contained"
            color="primary"
          >
            Update
          </Button>
        </Box>
      </Modal>
    </div>
  );
}

export default UpdateAccount;
