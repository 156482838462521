import axios from "axios";
const base = `${process.env.REACT_APP_EVENTS_API}/api/v1/events`;

axios.defaults.headers.common["events-api-key"] =
  localStorage.getItem("events-api-key");

//CORRECT API TO USE WHEN SET UP ** DO NO REMOVE
export const listAllEvents = async () => {
  try {
    const response = await axios.get(base);
    const events = response.data.data;
    if (!response.data.success) {
      throw Error("list events failed");
    }
    return events;
  } catch (e) {
    console.log("API Error", e);
    throw e;
  }
};

export const createEvent = async (
  service,
  event,
  samplePayload,
  description
) => {
  try {
    const response = await axios.post(`${base}`, {
      service,
      event,
      samplePayload,
      description,
    });
    if (!response.data.success) {
      throw Error("create event failed");
    }
    return response.data.data;
  } catch (e) {
    console.log("API Error", e);
    throw e;
  }
};

export const updateEvent = async (service, event, updateValues) => {
  try {
    const response = await axios.put(
      `${base}/service/${service}/event/${event}`,
      updateValues
    );
    if (!response.data.success) {
      throw Error("update event failed");
    }
    return { service, event, ...updateValues, ...response.data.data };
  } catch (e) {
    console.log("API Error", e);
    throw e;
  }
};

export const deleteEvent = async (service, event) => {
  try {
    const response = await axios.delete(
      `${base}/service/${service}/event/${event}`
    );
    if (!response.data.success) {
      throw Error("delete event failed");
    }
    return response.data;
  } catch (e) {
    console.log("API Error", e);
    throw e;
  }
};
