import React, { useState, useEffect } from "react";
import BaseCard from "./UI/BaseCard";
import ServiceTable from "./components/ServiceTable";
import EventTable from "./components/EventTable";
import EventServiceModal from "./components/EventServiceModal";
import Button from "@mui/material/Button";

import { listEventThunk } from "store/event/eventThunks";
import { listServiceThunk } from "store/service/serviceThunks";
import { useSelector, useDispatch } from "react-redux";
import deepEqual from "react-fast-compare";

const EventServicePage = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState({});
  const [showEditButton, setShowEditButton] = useState(false);
  const [editIndex, setEditIndex] = useState();
  const [modalType, setModalType] = useState("");

  const events = useSelector((state) => state.event.events, deepEqual);
  const services = useSelector((state) => state.service.services, deepEqual);

  useEffect(() => {
    dispatch(listEventThunk({}));
    dispatch(listServiceThunk({}));
  }, [dispatch]);

  //Open and Close Modal
  const handleModalOpen = (modalType) => {
    setShowEditButton(false);
    setOpen(true);
    setModalType(modalType);
  };

  const closeMainModal = () => {
    setOpen(false);
  };

  useEffect(() => {}, [editData]);
  //Edit button click passed from table
  const handlEditModal = (index) => {
    const editGrab = events[index];
    setEditData(editGrab);
    setOpen(true);
    setShowEditButton(true);
    setEditIndex(index);
  };

  //Clear Edit data
  const clearEditData = () => {
    setEditData({});
  };

  const setNewEditData = (data) => {
    setEditData(data);
  };

  return (
    <>
      <Button
        variant="contained"
        sx={{ mt: 3, mr: 3 }}
        onClick={() => handleModalOpen("service")}
      >
        Create Service
      </Button>

      <Button
        variant="contained"
        sx={{ mt: 3 }}
        onClick={() => handleModalOpen("event")}
      >
        Create Event
      </Button>

      <EventServiceModal
        mainModalClose={closeMainModal}
        open={open}
        editData={editData}
        serviceNames={services.map((service) => service.service)}
        clearEdit={clearEditData}
        showEditButton={showEditButton}
        editIndex={editIndex}
        updateData={setNewEditData}
        modalType={modalType}
      />

      <BaseCard cardTitle="">
        <ServiceTable />
      </BaseCard>

      <BaseCard cardTitle="">
        <EventTable openEditModal={handlEditModal} />
      </BaseCard>
    </>
  );
};

export default EventServicePage;
