import Card from "@mui/material/Card";
// import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
// import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

export default function BaseCard(props) {
  return (
    <Card sx={{ maxWidth: 800, elevation: 0, boxShadow: 3, m: "auto", mt: 4 }}>
      <CardContent>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          sx={{ textAlign: "left" }}
        >
          {props.cardTitle}
        </Typography>

        {props.children}
      </CardContent>
    </Card>
  );
}
