import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/styles";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import deepEqual from "react-fast-compare";
import { deleteAccountsThunk } from "store/accounts/accountsThunks";
import { deleteAccountEventsThunk } from "store/core-events/coreEventServiceThunks";
import { useConfirm } from "material-ui-confirm";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

function AccountTable(props) {
  const confirm = useConfirm();
  const dispatch = useDispatch();
  const accounts = useSelector((state) => state.accounts.accounts, deepEqual);
  const coreEvents = useSelector(
    (state) => state.coreEvents.coreEvents,
    deepEqual
  );
  let theme = createTheme({
    components: {
      MUIDataTableToolbar: {
        styleOverrides: {
          root: {
            textAlign: "left",
          },
        },
      },
    },
  });
  theme = responsiveFontSizes(theme);

  const columns = [
    {
      name: "awsAccountId",
      label: "AWS Account Id",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "accountId",
      label: "Account Id",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "clientName",
      label: "Client Name",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "awsRegion",
      label: "AWS Region",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "queueArn",
      label: "Queue Arn",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "queueUrl",
      label: "Queue URL",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "Delete",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          return (
            <IconButton
              aria-label="delete"
              color="warning"
              onClick={() => {
                deleteRow(dataIndex);
              }}
            >
              <DeleteIcon />
            </IconButton>
          );
        },
      },
    },
  ];

  const options = {
    selectableRows: "none",
  };

  const deleteRow = async (dataIndex) => {
    const accountForDeletion = accounts[dataIndex];
    const accountId = accounts[dataIndex].accountId;
    const associatedEvents = coreEvents.filter(
      (item) => item.accountId === accountId
    );
    const numberOfEvents = associatedEvents.length;
    await confirm({
      description: `This will permanently delete Account: ${accountId}.  
      This has ${numberOfEvents} event subscriptions associated with it.`,
    });
    dispatch(deleteAccountsThunk({ accountForDeletion, dataIndex }));
    dispatch(deleteAccountEventsThunk({ accountId, associatedEvents }));
  };

  return (
    <ThemeProvider theme={theme}>
      <MUIDataTable
        title={"Accounts"}
        data={accounts}
        columns={columns}
        options={options}
      />
    </ThemeProvider>
  );
}

export default AccountTable;
