import React, { useState } from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import BaseCard from "../UI/BaseCard";
import CloseIcon from "@mui/icons-material/Close";
import { createServiceThunk } from "store/service/serviceThunks";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

Service.propTypes = {
  closeIconClick: PropTypes.func.isRequired,
};

function Service(props) {
  const { closeIconClick } = props;
  const dispatch = useDispatch();
  const [serviceName, setServiceName] = useState("");
  const [serviceDescription, setServiceDescription] = useState("");
  const [serviceNameInvalid, setServiceNameInvalid] = useState(false);
  const [serviceDesciptionInvalid, setServiceDesciptionInvalid] =
    useState(false);

  const kebabCase = (string) =>
    string
      .replace(/([a-z])([A-Z])/g, "$1-$2")
      .replace(/[\s_]+/g, "-")
      .toLowerCase();

  //Input Handling
  const inputServiceName = (e) => {
    let createdServiceName = e.target.value;
    let kebabCreatedServiceName = kebabCase(createdServiceName);
    setServiceName(kebabCreatedServiceName);
    if (serviceName > 0) {
      setServiceNameInvalid(false);
    }
  };

  const inputServiceDescription = (e) => {
    let createdServiceDescription = e.target.value;
    setServiceDescription(createdServiceDescription);
    if (serviceDescription > 0) {
      setServiceDesciptionInvalid(false);
    }
  };

  //Creating new Service and Validation
  const handleCreateService = () => {
    if (serviceName.length < 1) {
      setServiceNameInvalid(true);
    }
    if (serviceDescription.length < 1) {
      setServiceDesciptionInvalid(true);
      return;
    }
    const newService = { serviceName, serviceDescription };
    dispatch(createServiceThunk(newService));
    setServiceName("");
    setServiceDescription("");
    handleModalClose();
  };

  //Modal Close and Clear
  const handleModalClose = () => {
    clearData();
    closeIconClick();
  };

  //Remove input fields
  const clearData = () => {
    setServiceName("");
    setServiceDescription("");
  };

  return (
    <BaseCard cardTitle="Create Service">
      <CloseIcon
        onClick={handleModalClose}
        sx={{ mt: -5, float: "right", cursor: "pointer" }}
      />
      <FormControl sx={{ display: "flex" }}>
        <TextField
          error={serviceNameInvalid}
          sx={{ mb: 2 }}
          id="service-input"
          label="Service Name"
          variant="outlined"
          value={serviceName}
          onChange={inputServiceName}
        />

        <TextField
          error={serviceDesciptionInvalid}
          sx={{ mb: 2 }}
          id="servicedescription"
          label="Service Description"
          variant="outlined"
          multiline
          rows={3}
          value={serviceDescription}
          onChange={inputServiceDescription}
        />

        <Stack spacing={2} direction="row">
          <Button
            variant="contained"
            sx={{ maxWidth: 100, display: "inline" }}
            onClick={handleCreateService}
          >
            Create
          </Button>
        </Stack>
      </FormControl>
    </BaseCard>
  );
}

export default Service;
