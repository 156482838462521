import { createSlice } from "@reduxjs/toolkit";
import state from "./serviceState";
import {
  createServiceThunk,
  listServiceThunk,
  deleteServicesThunk,
} from "./serviceThunks";

const app = createSlice({
  name: "services",
  initialState: state,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createServiceThunk.fulfilled, (state, action) => {
        state.services.push(action.payload.returnService);
      })

      .addCase(
        deleteServicesThunk.fulfilled,
        (state, { payload: { deletedServiceNames } }) => {
          state.services = state.services.filter(
            (item) => !deletedServiceNames.includes(item.service)
          );
        }
      )

      .addCase(listServiceThunk.fulfilled, (state, action) => {
        state.services = action.payload.services;
      })

      .addCase(listServiceThunk.pending, (state, action) => {
        state.status = "loading";
      });
  },
});

export default app.reducer;
